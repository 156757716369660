import React, { Fragment } from 'react';
import { useFetchingCard } from '../../../hook/useFetchingCard';
import { Preloader } from '../../../shared/Preloader';
import com from '../../../styles/common.module.scss';
import s from '../style/Home.module.scss';
import { Card } from '../../../components/CardsItems/Card/Card';

export const Cards = () => {
  const { cardData, isLoading } = useFetchingCard();

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <div className={`${com.container} ${com.cardWrapper}`}>
        {cardData.map(card => (
          <Card key={card.affiliate_url} {...card} />
        ))}
      </div>
      <div className={s.text}>
        <p>
          Настоящий сайт является составным произведением, которое представляет собой в
          том числе каталог товарных знаков (знаков обслуживания), опубликованных в
          открытых реестрах ФИПС (Роспатент) и сопровождающихся подобранным каталогом
          финансовых услуг. Исключительное право на товарные знаки (знаки обслуживания),
          представленные в вышеуказанном каталоге, принадлежат их правообладателям и
          охраняются в соответствии с выданными ФИПС (Роспатент) правоустанавливающими
          документами для индивидуализации товаров, работ или услуг их непосредственных
          правообладателей.
        </p>
      </div>
    </>
  );
};
