import React, { FC, useEffect } from 'react';
import { HelpInfo } from '../components/HelpInfo';
import { useHistoryWithUTM } from '../hook/useHistoryWithUTM';

import s from './App.module.scss';
import { Route, Switch } from 'react-router-dom';
import { Home } from './Home';
import { Helmet } from 'react-helmet';
import { DomainDataStore } from '../themes';
import { useMetrics } from '../metrics/hook/useMetrics';
import DocumentContainer from '../containers/DocumentContainer/DocumentContainer';

export const App: FC = () => {
  const history = useHistoryWithUTM();

  useEffect(() => {
    history.toHttps();
  }, []);

  useMetrics();

  return (
    <div className={s.wrapper}>
      <Helmet title={DomainDataStore.title.mircozaim} />
      <main className={s.page}>
        <Switch>
          <Route exact path="/" render={() => <Home />} />
        </Switch>
      </main>
      <HelpInfo />
      <DocumentContainer />
    </div>
  );
};
