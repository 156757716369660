import { DomainsName, getCurrentDomainName } from './helpers';

export const currentDomain: DomainsName = getCurrentDomainName();

type FooterType = 'first' | 'second';

export interface FooterFields {
  type: FooterType;
  hasAccordion: boolean;
  ip_owner: string;
  ip_number: string;
  email: string;
  phone: string;
  address: string;
  licenceNumber?: string;
  ogrnNumber?: string;
  orderNumber?: string;
}
interface HeaderFields {
  title: string;
  subtitle: string;
}
export const getShowCase = (credit_type: string) => {
  if (credit_type === 'kk') return 6;
  if (credit_type === 'kn') return 7;
  if (credit_type === 'mfo') return 8;
  return 3;
};

export type DomainProps<T> = { [key in DomainsName]: T };

type DomainDataStoreProps = {
  footer: DomainProps<FooterFields>;
  title: DomainProps<string>;
  header: DomainProps<HeaderFields>;
  showcase: DomainProps<number>;
  useSmartCard: DomainProps<boolean>;
  googleMetrics: DomainProps<string>;
  yandexMetrics: DomainProps<number>;
};

export const DomainDataStore: DomainDataStoreProps = {
  googleMetrics: {
    dengionline: 'GTM-MZKN8FM',
    kreditzaim: 'GTM-TW8X7PV',
    active_money: 'GTM-NQP7443',
    credit_online: 'GTM-5WK39SR',
    mircozaim: 'GTM-P5F5NFJ',
    fast_kred: 'GTM-NMF6C4X',
    zaem_system: 'GTM-M273FVQ',
  },
  yandexMetrics: {
    dengionline: 0,
    kreditzaim: 0,
    active_money: 0,
    credit_online: 0,
    mircozaim: 0,
    fast_kred: 0,
    zaem_system: 0,
  },
  useSmartCard: {
    dengionline: true,
    kreditzaim: true,
    active_money: true,
    credit_online: true,
    mircozaim: true,
    fast_kred: false,
    zaem_system: false,
  },

  showcase: {
    dengionline: 3,
    kreditzaim: 3,
    active_money: 3,
    credit_online: 3,
    mircozaim: 3,
    fast_kred: 5,
    zaem_system: 4,
  },

  header: {
    dengionline: {
      title: 'ВАМ ПРЕДВАРИТЕЛЬНО ОДОБРЕНЫ СЛЕДУЮЩИЕ ПРЕДЛОЖЕНИЯ',
      subtitle:
        'Для гарантированного получения денег рекомендуем отправлять заявку в 3-5 МФО',
    },
    kreditzaim: {
      title: 'Автоматический подбор займов с низкой ставкой',
      subtitle:
        'Вам предварительно одобрены следующие предложения! Оставьте заявку в 3 - 5 МФО',
    },
    active_money: {
      title: 'СЕРВИС ПОДБОРА ЗАЙМА НА КАРТУ',
      subtitle:
        'Разбейте желаемую сумму на несколько частей и отправьте заявки в 3-5 МФО для увеличения шансов одобрения',
    },
    credit_online: {
      title: 'Заём на карту онлайн круглосуточно',
      subtitle:
        'Выберите 2-3 МФО с подходящими условиями, подайте заявки и дождитесь одобрения',
    },
    mircozaim: {
      title: 'Вам предварительно одобрены следующие предложения',
      subtitle:
        'Для гарантированного получения денег рекомендуем отправлять заявку в 3-5 МФО',
    },
    fast_kred: {
      title: 'ВАМ АВТОМАТИЧЕСКИ ОДОБРЕНЫ СЛЕДУЮЩИЕ ПРЕДЛОЖЕНИЯ',
      subtitle:
        'Для гарантированного получения денег рекомендуем отправлять заявку в 3-5 организаций',
    },
    zaem_system: {
      title: 'ВАМ АВТОМАТИЧЕСКИ ОДОБРЕНЫ СЛЕДУЮЩИЕ ПРЕДЛОЖЕНИЯ',
      subtitle:
        'Для гарантированного получения денег рекомендуем отправлять заявку в 3-5 организаций',
    },
  },

  title: {
    dengionline: 'Выгодный заём',
    kreditzaim: 'Подбор займов',
    active_money: 'Выгодный заём',
    credit_online: 'Выгодный заём',
    mircozaim: 'Выгодный заём',
    fast_kred: 'Выгодный заём',
    zaem_system: 'Выгодный заём',
  },
  footer: {
    dengionline: {
      type: 'first',
      hasAccordion: true,
      ip_owner: 'Богданов ПП',
      ip_number: '670100066510',
      email: 'dengionline.site',
      phone: '+7 (495) 120-91-30',
      address: '109544, Москва, Большая Андроньевская ул., 8',
    },
    kreditzaim: {
      type: 'first',
      hasAccordion: true,
      ip_owner: 'Михайлов АМ',
      ip_number: '771674065345',
      email: '1kreditzaim.com.ru',
      phone: '+7 (495) 131-45-87',
      address: '115280, Москва, Пересветов пер., 6',
    },
    active_money: {
      type: 'first',
      hasAccordion: false,
      ip_owner: 'Кротова ИО',
      ip_number: '772021366290',
      email: 'active-money.ru',
      phone: '+7 (495) 341-89-12',
      address: '119049, Москва, ул. Большая Якиманка, 38',
    },
    credit_online: {
      type: 'second',
      hasAccordion: false,
      ip_owner: 'Струнова ОЛ',
      ip_number: '501000456705',
      email: 'credit-online.com.ru',
      phone: '+7 (495) 984-33-44',
      address: '107078, Москва, ул. Машкова, дом 5, стр. 1',
    },
    mircozaim: {
      type: 'first',
      hasAccordion: true,
      ip_owner: 'Самохина Е.А.',
      ip_number: '744613115073',
      email: 'microzaim.org',
      phone: '+7 (495) 478-12-04',
      licenceNumber: '74-18-004739',
      orderNumber: '212 от 23.11.2018',
      address:
        '455038, Россия, Челябинская область, г. Магнитогорск, пр-кт Ленина, д. 122, кв. 33',
    },
    fast_kred: {
      type: 'first',
      hasAccordion: true,
      ip_owner: 'Евсеев МВ',
      ip_number: '771604575864',
      email: 'fast-kred.ru',
      phone: '+7 (495) 567-09-56',
      address: '101000, Москва, Архангельский пер., 11',
    },
    zaem_system: {
      type: 'first',
      hasAccordion: true,
      ip_owner: 'Сотников АА',
      ip_number: '772793036401',
      email: 'zaemsystem.ru',
      phone: '+7 (495) 321-09-11',
      address: '119146, Москва, Фрунзенская наб., 16',
    },
  },
};
