import { useEffect, useState } from 'react';

import { AxiosError } from 'axios';

import { CardApi } from '../api/card-service';
import { CardDataType } from '../types';
import { smartCardData } from '../pages/Home/Cards/data';
import { DomainDataStore } from '../themes';

export const useFetchingCard = () => {
  const [cardData, setCardData] = useState([] as CardDataType[]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      const user_agent = window.navigator.userAgent || '';
      try {
        setIsLoading(true);
        const response = await CardApi.getCard({
          aff_sub2: '',
          user_agent,
          showcase: 3,
          credit_type: '',
        });
        const data: CardDataType[] = [];
        response.data.forEach((el, index) => {
          if (index === 4 && DomainDataStore.useSmartCard.mircozaim)
            data.push(smartCardData);
          data.push(el);
        });
        setCardData(data);
      } catch (err) {
        setError((err as AxiosError).message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return { cardData, isLoading, error };
};
