import React from 'react';
import { CardDataType } from '../../../types';
import {
  checkImgSource,
  checkPercentIfNone,
  checkSumIfNone,
  checkTermIfNone,
} from '../../../utils';
import s from './Card.module.scss';

export const Card: React.FC<CardDataType> = ({
  sum,
  age,
  term,
  percent,
  logo,
  advantage,
  affiliate_url,
  limit,
  cashback,
  delivery,
  period,
  bonus,
  name,
}) => (
  <div
    onClick={() => {
      window.open(affiliate_url, '_blank');
    }}
    className={s.card}
  >
    <div className={s.header}>
      <img className={s.header_logo} src={checkImgSource(logo, name)} alt="card-logo" />

      <button type="button" className={s.header_button}>
        Получить
      </button>
    </div>

    <div className={s.content}>
      <span className={s.content_item}>{(sum && checkSumIfNone(sum)) || limit}</span>
      <span className={`${s.content_item} ${s.ta_r}`}>
        {(term && checkTermIfNone(term)) || period}
      </span>
      <span className={s.content_item}>
        {checkPercentIfNone(percent || bonus || cashback || '')}
      </span>
      <span className={`${s.content_item} ${s.ta_r}`}>{age || delivery}</span>
    </div>

    <div className={s.advantage}>{advantage || 'Решение онлайн'}</div>
  </div>
);
