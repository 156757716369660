import { useEffect } from 'react';
import { DomainDataStore } from '../../themes';
import TagManager from 'react-gtm-module';

export const useMetrics = () => {
  useEffect(() => {
    const gtmId = DomainDataStore.googleMetrics.mircozaim;
    if (gtmId) TagManager.initialize({ gtmId });
  }, []);
};
