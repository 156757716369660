import React, { useState, FC } from 'react';

import plus from '../../assets/icons/plus.svg';
import minus from '../../assets/icons/minus.svg';

import s from './style/Accordion.module.scss';
import { AccordionPropsType } from './type';

export const Accordion: FC<AccordionPropsType> = ({ title, children }) => {
  const [isActive, setIsActive] = useState('');
  const [height, setHeight] = useState('0px');
  function toggleAccordion() {
    setIsActive(isActive === '' ? s.active : '');
    setHeight(isActive === s.active ? '0px' : `10000px`);
  }

  return (
    <article className={s.accordion}>
      <button
        id="not-open-window"
        type="button"
        className={s.accordion__button}
        onClick={toggleAccordion}
      >
        <p className={s.accordion__title} style={{ marginBottom: 0 }}>
          {title}
        </p>
        <img
          src={isActive === s.active ? minus : plus}
          className={`${s.accordion__icon}`}
          alt="arrow btn"
        />
      </button>
      <div style={{ maxHeight: `${height}` }} className={s.accordion__content}>
        <div className={s.accordion__text}>{children}</div>
      </div>
    </article>
  );
};
