import React from 'react';

import com from '../../styles/common.module.scss';

import s from './style/HelpInfo.module.scss';

import { Accordion } from 'shared/Accordion';
import { DomainDataStore } from '../../themes';
import { HelpInfoBody } from './components/HelpInfoBody';

export const HelpInfo = () => {
  const footerData = DomainDataStore.footer.mircozaim;

  return (
    <div className={s.footer}>
      <div className={`${com.container_small} ${com.container__footer}`}>
        <div className={s.info}>
          {footerData.hasAccordion ? (
            <Accordion title="Информация для клиентов">
              <HelpInfoBody footerData={footerData} />
            </Accordion>
          ) : (
            <>
              <h2 className={s.info__title}>Информация для клиентов</h2>
              <HelpInfoBody footerData={footerData} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
